window.addEventListener('DOMContentLoaded', function() {
  // Unencrypt all the rot13 stuff
  document.querySelectorAll('.encrypted').forEach(function(element) {
    element.innerHTML = element.innerHTML.replace(/[a-zA-Z]/g, function(c) {
      return String.fromCharCode((c <= "Z" ? 90:122) >= (c = c.charCodeAt(0)+13) ? c : c-26);
    });
  });

  // Add 'point' effect
  document.querySelectorAll('.pointable').forEach(function(element) {
    element.addEventListener('click', function(event) {
      let span = document.createElement('span');
      span.textContent = this.getAttribute('data-name');
      span.className = "float-away ignore points";
      document.body.appendChild(span);
      let rect = span.getBoundingClientRect();
      let pos = {
        x: event.clientX,
        y: event.clientY + window.pageYOffset
      };

      span.style.left = (pos.x - (rect.width  / 2)) + 'px';
      span.style.top  = (pos.y - (rect.height / 2)) + 'px';

      setTimeout(function() {
        document.body.removeChild(span);
      }, 1000);
    });
  });
});
