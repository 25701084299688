;(function() {
  "use strict";

  const readyStates = [
    "UNSENT",
    "OPENED",
    "HEADERS_RECEIVED",
    "LOADING",
    "DONE"
  ];

  window.addEventListener('DOMContentLoaded', function(event) {
    var form          = document.getElementById('upload-form');
    var statusElement = document.getElementById('status-element');
    var resultElement = document.getElementById('result-element');
    var uploadsList   = document.getElementById('uploads-list');

    function addPaths(paths) {
      for (let path of paths) {
        var li = document.createElement('li');
        li.innerHTML = `
          <input
            type="text"
            readonly
            value="${path}"
            size="${path.length + 2}"=
          >
        `;
        li.onclick = function(event) {
          this.querySelector('input').select();
          document.execCommand('copy');
        }
        uploadsList.appendChild(li);
      }
    }

    if (form) {
      form.onsubmit = function() {
        var formData = new FormData(form);
        var xhr = new XMLHttpRequest();

        xhr.onreadystatechange = function(e) {
          if (xhr.readyState === 4) {
            if (xhr.status >= 200 && xhr.status <= 299) {
              var paths = JSON.parse(xhr.responseText);
              addPaths(paths);
            } else {
              console.error(xhr);
            }
          }
        };

        xhr.onprogress = function () {
          // console.log('LOADING: ', xhr.status);
        };

        xhr.open('POST', form.action, true);
        xhr.send(formData);

        // Don't submit the form
        return false;
      }

      fetch('/admin/uploads.json')
        .then(response => response.json())
        .then(data => addPaths(data));
    }

  }, false);
}());
